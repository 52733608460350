import React from "react";
import Navigation from "../navigation/navigation";

const AboutUsNavigation = (props) => {
  return <Navigation {...props} />;
};

export default AboutUsNavigation;

AboutUsNavigation.defaultProps = {
  sectionClass: "bg-light py-3 mt-3",
  linksData: [
    {
      id: "nav-community-relations",
      href: "/about-us/community-giving",
      title: "Community Relations"
    },
    {
      id: "nav-community-financial-education",
      href: "/about-us/community-financial-education",
      title: "Financial Literacy"
    },
    {
      id: "nav-washington-federal-foundation",
      href: "/about-us/washington-federal-foundation",
      title: "Washington Federal Foundation"
    },
    {
      id: "nav-wafd-bank-community-and-social-responsibility",
      href: "/about-us/investor-relations/csr",
      title: "Corporate and Social Responsibility"
    },
    {
      id: "nav-community-meeting-rooms",
      href: "/about-us/community-meeting-rooms",
      title: "Community Meeting Rooms"
    },
    {
      id: "nav-community-reinvestment-act",
      href: "/about-us/community-reinvestment-act",
      title: "Community Reinvestment Act"
    }
  ]
};
